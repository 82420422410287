angular.module("vgresiduos").controller("storageListCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `listagem-geracoes`, [
				{ key: "residue", value: $stateParams.residuo },
				{ key: "residueName", value: $stateParams.nomeResiduo },
				{ key: "tab", value: $stateParams.tab },
				{ key: "pendency", value: $stateParams.pendencia }
			]);
		};
	}
]);
