angular.module("vgresiduos").controller("OrganizationChartsMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"clientService",
	function ($scope, $rootScope, httpService, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			if ($scope.$ctrl.level == Vgr.enumerations.organization.chart.Company) {
				$scope.service = clientService.listOrganizationCompanies;
				$scope.label = $scope.labels.COMPANY;
			} else if ($scope.$ctrl.level == Vgr.enumerations.organization.chart.Regional) {
				$scope.service = clientService.listOrganizationRegionals;
				$scope.label = $scope.labels.REGIONAL;
			} else {
				return;
			}

			listCharts();
		};

		function listCharts() {
			httpService.getListFromServiceCore($scope.service).then(function (response) {
				let list = response.list;
				list.forEach((element) => {
					element.id = element.id.toString();
					element.displayText = element.description;
				});
				$scope.charts = list;
			});
		}

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
