angular.module("vgresiduos").controller("GoalsReportCustomizedFilterCtrl", [
	"$rootScope",
	"$scope",
	"accountService",
	"$timeout",
	"reportUtilService",
	function ($rootScope, $scope, accountService, $timeout, reportUtilService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.periodEnum = Vgr.enumerations.reportFilters.periodEnum;

		$scope.defaultMeasureUnit = Vgr.constants.measureUnit.Ton.Id;

		const currentClient = accountService.getCurrentClient();

		this.$onInit = function () {
			initCustomizedFilter();
			initCustomFilters();
		};

		$scope.dateGroupByOptions = [$scope.periodEnum.GroupedByMonth];

		$scope.preFilterOptions = {
			title: $scope.labels.REPORT_CONFIGURATION,
			placeholder: $scope.labels.SELECT_METRIC_TO_VIEW_IN_REPORT,
			type: "select",
			options: [
				{
					value: Vgr.enumerations.goal.type.DisposalFinancial,
					description: $scope.labels.RESIDUE_DISPOSAL_COSTS_CHART_TITLE
				},
				{
					value: Vgr.enumerations.goal.type.ResidueGeneration,
					description: $scope.labels.RESIDUE_GENERATION_CHART_TITLE
				}
			],
			onChange: function (option) {
				initCustomizedFilter();
				if (option == Vgr.enumerations.goal.type.ResidueGeneration) {
					$scope.customizedFilterOptions.availableFields = reportUtilService.goalResidueGenerationReport.filters;
					$scope.measureUnitEnabled = true;
				} else {
					$scope.customizedFilterOptions.availableFields = reportUtilService.goalFinancialDiposalReport.filters;
					$scope.measureUnitEnabled = false;
				}
			}
		};

		function initCustomFilters() {
			$scope.filterLabels = $scope.labels.REPORT_GOALS_TITLE;

			$scope.customFilters = {
				PeriodGrouping: $scope.periodEnum.GroupedByMonth,
				DateRange: {
					dateId: 2
				},
				Grouping: []
			};
		}

		function clearDateFilter() {
			$scope.clearDateFilter = true;
			$timeout(function () {
				$scope.clearDateFilter = false;
			});
		}

		function initCustomizedFilter() {
			$scope.customizedFilterOptions = {
				isFilterActive: true,
				initialValues: [
					{
						id: reportUtilService.organizationUnitFilter.id,
						value: [currentClient.id]
					}
				],
				selectedFilters: []
			};
		}

		$scope.onFilter = function (originalFilters, formattedFilters) {
			if (!validGroupings()) {
				return false;
			}

			const reportConfig = {
				filters: formattedFilters
			};

			addCustomFiltersToFilters(reportConfig);
			return $scope.$ctrl.onFilter(originalFilters, reportConfig, $scope.preFilterOptions);
		};

		function validGroupings() {
			let valid = true;
			if (
				$scope.customFilters.Grouping &&
				$scope.customFilters.Grouping.length == 1 &&
				!$scope.customFilters.Grouping[0].field
			) {
				return true;
			}
			for (const grouping of $scope.customFilters.Grouping) {
				if (!grouping.field) {
					valid = false;
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.MANDATORY_FILL_ALL_GROUPINGS);
					break;
				} else if (
					grouping.field.type == Vgr.enumerations.customizedFilter.type.MetadataGrouping &&
					!grouping.value.Property
				) {
					valid = false;
					$rootScope.$broadcast(
						Vgr.constants.evtShowErrorMessage,
						$rootScope.labels.YOU_SELECTED_ONE_PROPERTY_PLEASE_PICK_ONE
					);
					break;
				}
			}
			return valid;
		}

		function addCustomFiltersToFilters(formattedFilters) {
			formattedFilters.PeriodGrouping = $scope.customFilters.PeriodGrouping;
			formattedFilters.MeasureUnit = $scope.customFilters.MeasureUnit;
			formattedFilters.Grouping = $scope.customFilters.Grouping;
			formattedFilters.DateRange = $scope.customFilters.DateRange;
			formattedFilters.Costs = $scope.customFilters.Costs;
			formattedFilters.MeasureUnit = $scope.customFilters.MeasureUnit;
		}

		$scope.onMeasureUnitChange = function (measureUnit) {
			$scope.customFilters.MeasureUnit = measureUnit;
		};

		$scope.onCleanFilters = function () {
			initCustomFilters();
			clearDateFilter();
		};
	}
]);
