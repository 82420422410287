angular.module("vgresiduos").factory("reportUtilService", [
	"$rootScope",
	"httpService",
	"$q",
	"clientService",
	"residueService",
	"areaService",
	"productV2Service",
	function ($rootScope, httpService, $q, clientService, residueService, areaService, productV2Service) {
		"use strict";

		const labels = $rootScope.labels;

		const baseInformation = {
			//location
			OrganizationUnit: "location.organizationUnit.id",
			OrganizationUnitProperty: "location.organizationUnit.property",
			OrganizationCompany: "location.organizationCompany",
			OrganizationRegional: "location.organizationRegional",
			CNPJ: "location.organizationUnit.document",
			City: "location.city",
			State: "location.state",
			Area: "location.area.id",
			AreaProperty: "location.area.property",
			CityDestinator: "destinator.city",

			//residue
			ResidueId: "residue.id",
			ResidueClass: "residue.class",
			ResidueTag: "residue.tag",
			ResiduePysicalState: "residue.physicalState",
			ResidueIbama: "residue.ibama",

			//generation
			GenerationProperty: "generation.property",
			GenerationRecipient: "generation.recipient",

			//disposal
			DisposalProperty: "disposal.property",
			DisposalType: "disposal.disposalType",
			DisposalRecipient: "disposal.recipient",
			Destinator: "disposal.destinator",
			Transporter: "disposal.transporter",
			TemporaryStorager: "disposal.temporaryStorager",
			PriceType: "disposal.priceType",

			//production
			Product: "production.product"
		};

		const _filterOrganizationUnit = {
			id: baseInformation.OrganizationUnit,
			type: Vgr.enumerations.customizedFilter.type.MultiSelect,
			label: labels.UNIT,
			category: labels.LOCATION,
			searchLabel: labels.FILTER_BY_CLIENT,
			displayProperty: "DescriptionToShow",
			key: "Id",
			loadList: function () {
				const deferred = $q.defer();
				httpService.getListFromServiceCore(clientService.listClientsCore, null).then(function (response) {
					response.list.forEach((client) => {
						client.DescriptionToShow = client.id + " - " + client.name;
						client.Id = client.id;
					});
					deferred.resolve(response.list);
				});
				return deferred.promise;
			},
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterOrganizationCompany = {
			id: baseInformation.OrganizationCompany,
			type: Vgr.enumerations.customizedFilter.type.MultiSelect,
			label: labels.COMPANY,
			category: labels.LOCATION,
			searchLabel: labels.FILTER_BY_COMPANY,
			displayProperty: "description",
			key: "id",
			loadList: function () {
				const deferred = $q.defer();
				httpService.getListFromServiceCore(clientService.listOrganizationCompanies, null).then(function (response) {
					deferred.resolve(response.list);
				});
				return deferred.promise;
			},
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterOrganizationRegional = {
			id: baseInformation.OrganizationRegional,
			type: Vgr.enumerations.customizedFilter.type.MultiSelect,
			label: labels.REGIONAL,
			category: labels.LOCATION,
			searchLabel: labels.FILTER_BY_REGIONAL,
			displayProperty: "description",
			key: "id",
			loadList: function () {
				const deferred = $q.defer();
				httpService.getListFromServiceCore(clientService.listOrganizationRegionals, null).then(function (response) {
					deferred.resolve(response.list);
				});
				return deferred.promise;
			},
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterOrganizationUnitProperty = {
			id: baseInformation.OrganizationUnitProperty,
			type: Vgr.enumerations.customizedFilter.type.Metadata,
			label: labels.UNIT_PROPERTY,
			category: labels.LOCATION,
			displayProperty: labels.PROPERTY,
			allowMultiple: true,
			option: Vgr.enumerations.additionalProperty.process.OrganizationUnit,
			isOptionEnabled: function () {
				return true;
			}
		};

		const _filterCity = {
			id: baseInformation.City,
			type: Vgr.enumerations.customizedFilter.type.City,
			label: labels.CITY,
			category: labels.LOCATION,
			key: "ID",
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterState = {
			id: baseInformation.State,
			type: Vgr.enumerations.customizedFilter.type.State,
			label: labels.STATE,
			category: labels.LOCATION,
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterResidueId = {
			id: baseInformation.ResidueId,
			type: Vgr.enumerations.customizedFilter.type.Residue,
			label: labels.RESIDUE,
			category: labels.RESIDUE,
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterResidueClass = {
			id: baseInformation.ResidueClass,
			type: Vgr.enumerations.customizedFilter.type.ResidueClass,
			label: labels.RESIDUE_CLASS,
			category: labels.RESIDUE,
			searchLabel: labels.FILTER_BY_RESIDUE_CLASS,
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterResidueTag = {
			id: baseInformation.ResidueTag,
			type: Vgr.enumerations.customizedFilter.type.MultiSelect,
			label: labels.RESIDUE_TAG,
			category: labels.RESIDUE,
			searchLabel: labels.FILTER_BY_TAG,
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterResiduePhysicalState = {
			id: baseInformation.ResiduePysicalState,
			type: Vgr.enumerations.customizedFilter.type.MultiSelect,
			label: labels.PHYSIC_STATE,
			category: labels.RESIDUE,
			searchLabel: labels.FILTER_BY_PHYSICAL_STATE,
			displayProperty: "Description",
			key: "ID",
			loadList: function () {
				return httpService.getListFromService(residueService.getPhysicStateList);
			},
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterGenerationProperty = {
			id: baseInformation.GenerationProperty,
			type: Vgr.enumerations.customizedFilter.type.Metadata,
			label: labels.INTERNAL_GATHERING_PROPERTY,
			category: labels.GENERATION,
			displayProperty: labels.PROPERTY,
			allowMultiple: true,
			option: Vgr.enumerations.additionalProperty.process.InternalGathering,
			isOptionEnabled: function () {
				return true;
			}
		};

		const _filterArea = {
			id: baseInformation.Area,
			type: Vgr.enumerations.customizedFilter.type.MultiSelect,
			searchLabel: labels.FILTER_BY_AREA,
			label: labels.AREA,
			category: labels.LOCATION,
			displayProperty: "name",
			key: "id",
			loadList: function () {
				const deferred = $q.defer();

				httpService.getListFromServiceCore(areaService.listOrganizationAreas).then(
					function (response) {
						deferred.resolve(response.list);
					},
					function (error) {
						deferred.reject(error);
					}
				);

				return deferred.promise;
			},
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterAreaProperty = {
			id: baseInformation.AreaProperty,
			type: Vgr.enumerations.customizedFilter.type.Metadata,
			label: labels.AREA_PROPERTY,
			category: labels.LOCATION,
			displayProperty: labels.PROPERTY,
			allowMultiple: true,
			option: Vgr.enumerations.additionalProperty.process.Area,
			isOptionEnabled: function () {
				return true;
			}
		};

		const _filterGenerationRecipient = {
			id: baseInformation.GenerationRecipient,
			type: Vgr.enumerations.customizedFilter.type.ClientRecipient,
			label: labels.PACKAGING,
			category: labels.GENERATION,
			searchLabel: labels.FILTER_BY_CLIENT_RECIPIENT,
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterDisposalRecipient = {
			id: baseInformation.DisposalRecipient,
			type: Vgr.enumerations.customizedFilter.type.ClientRecipient,
			label: labels.PACKAGING,
			category: labels.DISPOSAL,
			searchLabel: labels.FILTER_BY_CLIENT_RECIPIENT,
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterDisposalProperty = {
			id: baseInformation.DisposalProperty,
			type: Vgr.enumerations.customizedFilter.type.Metadata,
			label: labels.DISPOSAL_PROPERTY,
			category: labels.DISPOSAL,
			displayProperty: labels.PROPERTY,
			allowMultiple: true,
			option: Vgr.enumerations.additionalProperty.process.Disposal,
			isOptionEnabled: function () {
				return true;
			}
		};

		const _filterDisposalType = {
			id: baseInformation.DisposalType,
			type: Vgr.enumerations.customizedFilter.type.DisposalTech,
			label: labels.DISPOSAL_TECH,
			category: labels.DISPOSAL,
			searchLabel: labels.FILTER_BY_DISPOSAL_TECH,
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterDestinator = {
			id: baseInformation.Destinator,
			type: Vgr.enumerations.customizedFilter.type.Supplier,
			label: labels.DESTINATOR,
			category: labels.DISPOSAL,
			searchLabel: labels.FILTER_BY_SUPPLIER,
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterTransporter = {
			id: baseInformation.Transporter,
			type: Vgr.enumerations.customizedFilter.type.Transporter,
			label: labels.TRANSPORTER,
			category: labels.DISPOSAL,
			searchLabel: labels.FILTER_BY_TRANSPORTER,
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterTemporaryStorager = {
			id: baseInformation.TemporaryStorager,
			type: Vgr.enumerations.customizedFilter.type.TemporaryStorager,
			label: labels.TEMPORARY_STORAGER,
			category: labels.DISPOSAL,
			searchLabel: labels.FILTER_BY_TEMPORARY_STORAGER,
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterPriceType = {
			id: baseInformation.PriceType,
			type: Vgr.enumerations.customizedFilter.type.Select,
			label: labels.PRICING_REVENUE_EXPENSE,
			category: labels.DISPOSAL,
			displayProperty: "description",
			key: "id",
			list: [
				{
					id: "revenue",
					description: labels.INCOME
				},
				{
					id: "expense",
					description: labels.EXPENSE
				}
			],
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _filterProduct = {
			id: baseInformation.Product,
			type: Vgr.enumerations.customizedFilter.type.MultiSelect,
			searchLabel: labels.FILTER_BY_PRODUCT,
			label: labels.PRODUCT,
			category: labels.PRODUCTION,
			displayProperty: "descriptionFormated",
			key: "id",
			loadList: function () {
				const deferred = $q.defer();

				httpService.getListFromServiceCore(productV2Service.listOrganizationProducts).then(
					function (response) {
						const list = response.list;
						list.forEach((element) => {
							element.descriptionFormated = element.code + " - " + element.name;
						});
						deferred.resolve(list);
					},
					function (error) {
						deferred.reject(error);
					}
				);

				return deferred.promise;
			},
			isEnabled: true,
			isOptionEnabled: function () {
				return this.isEnabled;
			}
		};

		const _groupingOrganizationUnit = {
			id: baseInformation.OrganizationUnit,
			category: labels.LOCATION,
			label: labels.UNIT
		};

		const _groupingOrganizationUnitProperty = {
			id: baseInformation.OrganizationUnitProperty,
			category: labels.LOCATION,
			label: labels.UNIT_PROPERTY
		};

		const _groupingOrganizationCompany = {
			id: baseInformation.OrganizationCompany,
			category: labels.LOCATION,
			label: labels.COMPANY
		};

		const _groupingOrganizationRegional = {
			id: baseInformation.OrganizationRegional,
			category: labels.LOCATION,
			label: labels.REGIONAL
		};

		const _groupingCNPJ = {
			id: baseInformation.CNPJ,
			category: labels.LOCATION,
			label: labels.CNPJ
		};

		const _groupingCity = {
			id: baseInformation.City,
			category: labels.LOCATION,
			label: labels.CITY
		};

		const _groupingState = {
			id: baseInformation.State,
			category: labels.LOCATION,
			label: labels.STATE
		};

		const _groupingResidueId = {
			id: baseInformation.ResidueId,
			category: labels.RESIDUE,
			label: labels.RESIDUE
		};

		const _groupingResidueClass = {
			id: baseInformation.ResidueClass,
			category: labels.RESIDUE,
			label: labels.RESIDUE_CLASS
		};

		const _groupingResidueTag = {
			id: baseInformation.ResidueTag,
			category: labels.RESIDUE,
			label: labels.RESIDUE_TAG
		};

		const _groupingResiduePhysicalState = {
			id: baseInformation.ResiduePysicalState,
			category: labels.RESIDUE,
			label: labels.PHYSIC_STATE
		};

		const _groupingResidueIbama = {
			id: baseInformation.ResidueIbama,
			category: labels.RESIDUE,
			label: labels.IBAMA_RESIDUE
		};

		const _groupingGenerationProperty = {
			id: baseInformation.GenerationProperty,
			category: labels.GENERATION,
			label: labels.INTERNAL_GATHERING_PROPERTY
		};

		const _groupingGenerationRecipient = {
			id: baseInformation.GenerationRecipient,
			category: labels.GENERATION,
			label: labels.PACKAGING
		};

		const _groupingArea = {
			id: baseInformation.Area,
			category: labels.LOCATION,
			label: labels.AREA
		};

		const _groupingAreaProperty = {
			id: baseInformation.AreaProperty,
			category: labels.LOCATION,
			label: labels.AREA_PROPERTY
		};

		const _groupingDisposalProperty = {
			id: baseInformation.DisposalProperty,
			category: labels.DISPOSAL,
			label: labels.DISPOSAL_PROPERTY
		};

		const _groupingDisposalType = {
			id: baseInformation.DisposalType,
			category: labels.DISPOSAL,
			label: labels.DISPOSAL_TECH
		};

		const _groupingDisposalRecipient = {
			id: baseInformation.DisposalRecipient,
			category: labels.DISPOSAL,
			label: labels.PACKAGING
		};

		const _groupingDestinator = {
			id: baseInformation.Destinator,
			category: labels.DISPOSAL,
			label: labels.DESTINATOR
		};

		const _groupingCityDestinator = {
			id: baseInformation.CityDestinator,
			category: labels.LOCATION,
			label: labels.DESTINY_CITY
		};

		const _groupingTransporter = {
			id: baseInformation.Transporter,
			category: labels.DISPOSAL,
			label: labels.TRANSPORTER
		};

		const _groupingTemporaryStorager = {
			id: baseInformation.TemporaryStorager,
			category: labels.DISPOSAL,
			label: labels.TEMPORARY_STORAGER
		};

		const _groupingPriceType = {
			id: baseInformation.PriceType,
			category: labels.DISPOSAL,
			label: labels.PRICING_REVENUE_EXPENSE
		};

		const _groupingProduct = {
			id: baseInformation.Product,
			category: labels.PRODUCT,
			label: labels.PRODUCT
		};

		const _formatFilterToDto = function (filters) {
			const dto = [];

			for (const filter of filters) {
				if (!filter.field) {
					continue;
				}

				const newFilter = {
					Key: filter.field.id,
					Values: []
				};

				if (Array.isArray(filter.value)) {
					newFilter.Values = filter.value;
				} else if (filter.value.Property) {
					newFilter.Values = [
						filter.value.PropertyValue.id ? filter.value.PropertyValue.id : filter.value.PropertyValue
					];
					newFilter.PropertyId = filter.value.Property.id;
				} else if (typeof filter.value === "object" && filter.field.key) {
					newFilter.Values = [filter.value[filter.field.key]];
				} else {
					newFilter.Values = [filter.value];
				}

				dto.push(newFilter);
			}

			return dto;
		};

		const _formatGroupingToDto = function (groupings) {
			if (!groupings) {
				return [];
			}

			const ret = [];
			for (const grouping of groupings) {
				if (!grouping.field) {
					continue;
				}

				const groupObj = {
					Key: grouping.field.id
				};

				if (grouping.value.Property && grouping.value.Property.id) {
					groupObj.PropertyId = grouping.value.Property.id;
				}

				ret.push(groupObj);
			}
			return ret;
		};

		const _getHearders = function (header) {
			if (!header.groups && !header.dataQualifiers && !header.intervals && !header.total) {
				return header;
			}

			const convertedHeader = {
				Groups: [],
				DataQualifiers: []
			};

			angular.copy(header.groups, convertedHeader.Groups);
			angular.copy(header.dataQualifiers, convertedHeader.DataQualifiers);

			if (header.intervals) {
				convertedHeader.Intervals = [];
				angular.copy(header.intervals, convertedHeader.Intervals);
			}
			if (header.total) {
				convertedHeader.Total = header.total;
			}

			return convertedHeader;
		};

		const _getHeadersConcatenatedArrayFromDto = function (headers, ignoreTotal, concatenateFirstDataQualifier) {
			let concatenatedArray = [];

			const groups = headers.Groups ? headers.Groups : headers.groups;
			const dataQualifiers = headers.DataQualifiers ? headers.DataQualifiers : headers.dataQualifiers;
			const intervals = headers.Intervals ? headers.Intervals : headers.intervals;
			const total = headers.Total ? headers.Total : headers.total;

			if (groups) {
				concatenatedArray = concatenatedArray.concat(groups);
			}
			if (dataQualifiers && !concatenateFirstDataQualifier) {
				concatenatedArray = concatenatedArray.concat(dataQualifiers);
			}
			if (intervals) {
				concatenatedArray = concatenatedArray.concat(intervals);
			}
			if (total && !ignoreTotal) {
				concatenatedArray = concatenatedArray.concat([concatenateFirstDataQualifier ? dataQualifiers[0] : total]);
			}

			return concatenatedArray;
		};

		const _getDataQualifiersFromDto = function (data) {
			const report = new Object();
			for (const row of data) {
				if (!report[row.DataQualifiers[0]]) {
					report[row.DataQualifiers[0]] = [];
				}
				report[row.DataQualifiers[0]].push(row);
			}

			return report;
		};

		const _addTotalRowByQualifier = function (dataQualifiers) {
			for (const key in dataQualifiers) {
				const dataQualifier = dataQualifiers[key];
				const firstRow = dataQualifier[0];

				const row = {
					DataQualifiers: angular.copy(firstRow.DataQualifiers),
					Groups: [labels.TOTAL],
					Intervals: angular.copy(firstRow.Intervals),
					Total: angular.copy(firstRow.Total),
					isTotalRow: true
				};

				for (let groupIndex = 1; groupIndex < firstRow.Groups.length; groupIndex++) {
					row.Groups.push("");
				}

				for (let index = 1; index < dataQualifier.length; index++) {
					const currentRow = dataQualifier[index];
					for (const intervalIndex in currentRow.Intervals) {
						const currentRowInterval = currentRow.Intervals[intervalIndex];
						row.Intervals[intervalIndex] += currentRowInterval;
					}
					row.Total += currentRow.Total;
				}
				dataQualifier.push(row);
			}

			return dataQualifiers;
		};

		const reportUtilService = {};

		reportUtilService.organizationUnitFilter = _filterOrganizationUnit;
		reportUtilService.groupingResidueId = _groupingResidueId;
		reportUtilService.groupingDisposalRecipient = _groupingDisposalRecipient;

		reportUtilService.filterIds = baseInformation;

		reportUtilService.residueGenerationReport = {
			filters: [
				_filterOrganizationUnit,
				_filterOrganizationUnitProperty,
				_filterOrganizationRegional,
				_filterOrganizationCompany,
				_filterCity,
				_filterState,

				_filterResidueId,
				_filterResidueClass,
				_filterResidueTag,
				_filterResiduePhysicalState,

				_filterGenerationProperty,
				_filterAreaProperty,
				_filterArea
				// _filterGenerationRecipient
			],
			groupings: [
				_groupingOrganizationUnit,
				_groupingOrganizationUnitProperty,
				_groupingOrganizationCompany,
				_groupingOrganizationRegional,
				_groupingCNPJ,
				_groupingCity,
				_groupingState,

				_groupingResidueId,
				_groupingResidueClass,
				_groupingResidueTag,
				_groupingResiduePhysicalState,
				_groupingResidueIbama,

				_groupingGenerationProperty,
				_groupingGenerationRecipient,
				_groupingAreaProperty,
				_groupingArea
			]
		};

		reportUtilService.residueDisposalReport = {
			filters: [
				_filterOrganizationUnit,
				_filterOrganizationUnitProperty,
				_filterOrganizationRegional,
				_filterOrganizationCompany,
				_filterCity,
				_filterState,

				_filterResidueId,
				_filterResidueClass,
				_filterResidueTag,
				_filterResiduePhysicalState,

				_filterDisposalProperty,
				_filterDisposalType,
				_filterDestinator,
				_filterTransporter,
				_filterTemporaryStorager,
				_filterPriceType,

				_filterGenerationProperty,
				_filterAreaProperty,
				_filterArea
			],
			groupings: [
				_groupingOrganizationUnit,
				_groupingOrganizationUnitProperty,
				_groupingOrganizationCompany,
				_groupingOrganizationRegional,
				_groupingCNPJ,
				_groupingCity,
				_groupingState,

				_groupingResidueId,
				_groupingResidueClass,
				_groupingResidueTag,
				_groupingResiduePhysicalState,
				_groupingResidueIbama,

				_groupingDisposalProperty,
				_groupingDisposalType,
				_groupingDisposalRecipient,
				_groupingDestinator,
				_groupingCityDestinator,
				_groupingTransporter,
				_groupingTemporaryStorager,
				_groupingPriceType,

				_groupingGenerationProperty,
				_groupingAreaProperty,
				_groupingArea
			]
		};

		reportUtilService.productionReport = {
			filters: [
				_filterOrganizationUnit,
				_filterOrganizationUnitProperty,
				_filterOrganizationRegional,
				_filterOrganizationCompany,
				_filterCity,
				_filterState,

				_filterProduct,
				_filterAreaProperty,
				_filterArea
			],
			groupings: [
				_groupingOrganizationUnit,
				_groupingOrganizationUnitProperty,
				_groupingOrganizationCompany,
				_groupingOrganizationRegional,
				_groupingCNPJ,
				_groupingCity,
				_groupingState,

				_groupingProduct,
				_groupingAreaProperty,
				_groupingArea
			]
		};

		reportUtilService.recipientEfficiencyReport = {
			filters: [
				_filterOrganizationUnit,
				_filterOrganizationUnitProperty,
				_filterOrganizationRegional,
				_filterOrganizationCompany,
				_filterCity,
				_filterState,

				_filterResidueId,
				_filterResidueClass,
				_filterResidueTag,
				_filterResiduePhysicalState,

				_filterDisposalProperty,
				_filterDisposalType,
				_filterDestinator,
				_filterTransporter,
				_filterTemporaryStorager,
				_filterDisposalRecipient
			],
			groupings: [
				_groupingOrganizationUnit,
				_groupingOrganizationUnitProperty,
				_groupingOrganizationCompany,
				_groupingOrganizationRegional,
				_groupingCNPJ,
				_groupingCity,
				_groupingState,

				_groupingResidueId,
				_groupingResidueClass,
				_groupingResidueTag,
				_groupingResiduePhysicalState,
				_groupingResidueIbama,

				_groupingDisposalProperty,
				_groupingDisposalType,
				_groupingDisposalRecipient,
				_groupingDestinator,
				_groupingCityDestinator,
				_groupingTransporter,
				_groupingTemporaryStorager
			]
		};

		reportUtilService.unitBuiltAreaReport = {
			filters: [
				_filterOrganizationUnit,
				_filterOrganizationUnitProperty,
				_filterOrganizationRegional,
				_filterOrganizationCompany,
				_filterCity,
				_filterState
			],
			groupings: [
				_groupingOrganizationUnit,
				_groupingOrganizationUnitProperty,
				_groupingOrganizationCompany,
				_groupingOrganizationRegional,
				_groupingCNPJ,
				_groupingCity,
				_groupingState
			]
		};

		reportUtilService.goalFinancialDiposalReport = {
			filters: [
				_filterOrganizationUnit,
				_filterOrganizationUnitProperty,
				_filterOrganizationRegional,
				_filterOrganizationCompany,
				_filterCity,
				_filterState,
				_filterPriceType,
				_filterResidueId
			]
		};

		reportUtilService.goalResidueGenerationReport = {
			filters: [
				_filterOrganizationUnit,
				_filterOrganizationUnitProperty,
				_filterOrganizationRegional,
				_filterOrganizationCompany,
				_filterCity,
				_filterState,
				_filterResidueId
			]
		};

		const _buildCustomReportFilterDto = function (filters, reportType, reportName) {
			const dto = {};

			if (reportType) {
				dto.type = reportType.id;
			}
			if (reportName) {
				dto.description = reportName;
			}

			if (filters) {
				dto.informationId = filters.information ? filters.information.id : null;

				dto.dateRange = buildDateRangeDto(filters);
				dto.filters = buildFiltersDtos(filters);
				dto.groupings = buildGroupingDtos(filters);
				dto.residueDisposalPricePreferences = buildResidueDisposalPricePreferences(filters);
				dto.measureUnitPreference = buildMeasureUnitPreference(filters);
			}

			return dto;
		};

		const _listCustomReportsDateRangeIntervals = function () {
			return [
				{
					Id: Vgr.enumerations.customReport.dateRangeOption.LastMonth,
					Description: $rootScope.labels.PREVIOUS_MONTH
				},
				{
					Id: Vgr.enumerations.customReport.dateRangeOption.CurrentMonth,
					Description: $rootScope.labels.CURRENT_MONTH
				},
				{
					Id: Vgr.enumerations.customReport.dateRangeOption.LastYear,
					Description: $rootScope.labels.PREVIOUS_YEAR
				},
				{
					Id: Vgr.enumerations.customReport.dateRangeOption.CurrentYear,
					Description: $rootScope.labels.CURRENT_YEAR
				},
				{
					Id: Vgr.enumerations.customReport.dateRangeOption.Last12Months,
					Description: $rootScope.labels.LAST_TWELVE_MONTHS
				},
				{
					Id: Vgr.enumerations.customReport.dateRangeOption.Custom,
					Description: $rootScope.labels.CUSTOM
				}
			];
		};

		const _listCustomReportsDateRangeGroupings = function () {
			return [
				{
					id: Vgr.enumerations.customReport.dateRangeGrouping.Total,
					description: $rootScope.labels.TOTAL_IN_PERIOD
				},
				{
					id: Vgr.enumerations.customReport.dateRangeGrouping.ByMonth,
					description: $rootScope.labels.MONTH
				}
			];
		};

		const _listCustomReportsDisposalPricePreferences = function () {
			return [
				{
					id: Vgr.enumerations.reportFilters.costsEnum.Residue,
					description: $rootScope.labels.COSTS_ENUM[Vgr.enumerations.reportFilters.costsEnum.Residue]
				},
				{
					id: Vgr.enumerations.reportFilters.costsEnum.Shipping,
					description: $rootScope.labels.COSTS_ENUM[Vgr.enumerations.reportFilters.costsEnum.Shipping]
				},
				{
					id: Vgr.enumerations.reportFilters.costsEnum.Other,
					description: $rootScope.labels.COSTS_ENUM[Vgr.enumerations.reportFilters.costsEnum.Other]
				}
			];
		};

		const _getCustomReportsDisposalPricePreference = function (id) {
			return _listCustomReportsDisposalPricePreferences().find((preference) => preference.id == id);
		};

		function buildDateRangeDto(filters) {
			if (filters.dateFilter) {
				return {
					option: filters.dateFilter.option,
					startDate: filters.dateFilter.beginDate,
					endDate: filters.dateFilter.endDate,
					rangeGroup: filters.dateFilter.grouping
				};
			}
			return null;
		}

		function buildFiltersDtos(filters) {
			if (filters.filters) {
				return filters.filters.map(function (filter) {
					return {
						propertyCode: filter.item.id,
						additionalPropertyId: filter.additionalProperty ? filter.additionalProperty.id : null,
						values: getValuesFromFilter(filter)
					};
				});
			}
			return [];
		}

		function getValuesFromFilter(filter) {
			if (!filter.values) {
				return [];
			}

			return filter.values.map(function (value) {
				return value.id;
			});
		}

		function buildGroupingDtos(filters) {
			if (filters.groupBys) {
				return filters.groupBys.map(function (groupBy) {
					return {
						propertyCode: groupBy.item.id,
						additionalPropertyId: groupBy.additionalProperty ? groupBy.additionalProperty.id : null
					};
				});
			}
			return [];
		}

		function buildResidueDisposalPricePreferences(filters) {
			if (filters.preferences && filters.preferences.disposalPricePreferences) {
				return {
					useResidue: filters.preferences.disposalPricePreferences.residue,
					useShipping: filters.preferences.disposalPricePreferences.shipping,
					useOthers: filters.preferences.disposalPricePreferences.other
				};
			}
			return null;
		}

		function buildMeasureUnitPreference(filters) {
			if (filters.preferences && filters.preferences.measureUnitPreferences) {
				return {
					option: filters.preferences.measureUnitPreferences.option,
					measureUnitId: filters.preferences.measureUnitPreferences.measureUnit
						? filters.preferences.measureUnitPreferences.measureUnit.id
						: null
				};
			}
			return null;
		}

		reportUtilService.formatFilterToDto = _formatFilterToDto;
		reportUtilService.formatGroupingToDto = _formatGroupingToDto;
		reportUtilService.getHearders = _getHearders;
		reportUtilService.getHeadersConcatenatedArrayFromDto = _getHeadersConcatenatedArrayFromDto;
		reportUtilService.getDataQualifiersFromDto = _getDataQualifiersFromDto;
		reportUtilService.addTotalRowByQualifier = _addTotalRowByQualifier;

		reportUtilService.buildCustomReportFilterDto = _buildCustomReportFilterDto;

		reportUtilService.listCustomReportsDateRangeIntervals = _listCustomReportsDateRangeIntervals;
		reportUtilService.listCustomReportsDateRangeGroupings = _listCustomReportsDateRangeGroupings;
		reportUtilService.listCustomReportsDisposalPricePreferences = _listCustomReportsDisposalPricePreferences;
		reportUtilService.getCustomReportsDisposalPricePreference = _getCustomReportsDisposalPricePreference;

		return reportUtilService;
	}
]);
