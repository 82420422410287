angular.module("vgresiduos").component("organizationChartsMultiSelect", {
	controller: "OrganizationChartsMultiSelectCtrl",
	templateUrl:
		"views/components/multiSelects/organizationChartsMultiSelect/organizationChartsMultiSelect_template.html",
	bindings: {
		level: "=",
		setter: "<?",
		callback: "<",

		clear: "<?"
	}
});
